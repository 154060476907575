import React from 'react';
import { string } from 'prop-types';
import { Box, Heading } from '@chakra-ui/react';

import { GIRL_NAME_SHORT, BOY_NAME_SHORT, GIRL_NAME_SHORT2, BOY_NAME_SHORT2 } from '@/constants';
import { BG_WELCOMING_SECTION } from '@/constants/assets';
import useAlternative from '@hooks/useAlternative';

function WelcomeV4({ ...rest }) {
  const isAlternative = useAlternative();
  return (
    <Box
      padding="32px"
      bgColor="bgPrimary"
      bgImage={`url(${BG_WELCOMING_SECTION})`}
      bgSize="cover"
      bgPos="top 20px"
      minHeight="80vh"
      {...rest}
    >
      {/* Dear Section */}
      <Heading
        marginTop="42px"
        textColor="secondaryColorText"
        fontWeight="normal"
        fontSize="xs"
        textAlign="center"
        letterSpacing="3px"
        marginBottom="8px"
      >
        THE WEDDING OF
      </Heading>
      <Box
        textAlign="center"
        textColor="secondaryColorText"
        textTransform="uppercase"
        letterSpacing="4px"
      >
        <Heading fontWeight="normal" fontSize="4xl">
          {isAlternative ? GIRL_NAME_SHORT2 : GIRL_NAME_SHORT} &
        </Heading>
        <Heading fontWeight="normal" fontSize="4xl" marginTop="-22px">
          {isAlternative ? BOY_NAME_SHORT2 : BOY_NAME_SHORT}
        </Heading>
      </Box>
    </Box>
  );
}

WelcomeV4.propTypes = {
  lang: string.isRequired,
};

export default WelcomeV4;
