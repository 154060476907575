// const img = '';

export default [
  // {
  //   img: img,
  //   title: '',
  //   description: `They first met in high school. She dances, & he does beatbox. An odd pair, that just somehow worked. They were classmates, so they got along. Until they became best friends. She would record the silly things he did & post them on Instagram. He would finish off her food when she ordered too much. What started off as buzzing texts & endless chatters, turned into something more. They never knew what they were in for…11 years.
  //   <br/>
  //   She is ready to play the song/ he absolutely hates when he drives her to work daily. He is ready to annoy her for the rest of her life. They are ready for forever being reckless kids trying to navigate through adulthood together.
  //   <br/>
  //   There’s no denying that they are the happiest when together.
  //   As they embrace #FLOReverwithNIC.
  //   `,
  // },
];
