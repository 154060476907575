import React from 'react';
import { Box, Text, Divider, Center } from '@chakra-ui/react';

import useInvitation from '@hooks/useInvitation';
import DetailCard from './DetailCard';

import {
  GIRL_NAME,
  BOY_NAME,
  IG_GIRL,
  IG_BOY,
  GIRL_PARENT_NAME,
  BOY_PARENT_NAME,
  IS_BOY_FIRST,
} from '@/constants';
// adding comment for trigger build
import { IMG_MAN, IMG_GIRL } from '@/constants/assets';
import { MUSLIM_INVITATION } from '@constants/feature-flags';
import txt from './locales.js';
import useLang from '@hooks/useLang';
import useAlternative from '@hooks/useAlternative';

function CoupleInfo({ ...rest }) {
  const isInvitation = useInvitation();
  const lang = useLang();
  const isAlt = useAlternative();

  return (
    <Box bgColor="bgPrimary">
      {false && (
        <Center>
          <Divider
            opacity={1}
            bgColor="blackAlpha.200"
            borderBottom="2px solid"
            borderBottomColor="blackAlpha.100"
            maxWidth="200px"
          />
        </Center>
      )}
      <Box padding="0 0 12px 0" {...rest}>
        {MUSLIM_INVITATION ? (
          <Text textAlign="center" padding="0 24px" color="mainColorText">
            {isInvitation ? (
              <span dangerouslySetInnerHTML={{ __html: txt.openingMuslimInvitation[lang] }} />
            ) : (
              <span dangerouslySetInnerHTML={{ __html: txt.openingMuslimAnnouncement[lang] }} />
            )}
          </Text>
        ) : (
          <Text textAlign="center" padding="0 24px" color="mainColorText">
            {isInvitation ? (
              <span dangerouslySetInnerHTML={{ __html: txt.openingGeneralInvitation[lang] }} />
            ) : (
              <span dangerouslySetInnerHTML={{ __html: txt.openingGeneralAnnouncement[lang] }} />
            )}
          </Text>
        )}
        <DetailCard
          fullName={isAlt ? (IS_BOY_FIRST ? 'Nico' : 'Keke') : IS_BOY_FIRST ? BOY_NAME : GIRL_NAME}
          imgUrl={IS_BOY_FIRST ? IMG_MAN : IMG_GIRL}
          instagramId={!isAlt ? (IS_BOY_FIRST ? IG_BOY : IG_GIRL) : ''}
          description={IS_BOY_FIRST ? BOY_PARENT_NAME : GIRL_PARENT_NAME}
        />
        <Text
          textAlign="center"
          color="mainColorText"
          fontFamily="heading"
          fontSize="6xl"
        >{`&`}</Text>
        <DetailCard
          fullName={isAlt ? (IS_BOY_FIRST ? 'Keke' : 'Nico') : IS_BOY_FIRST ? GIRL_NAME : BOY_NAME}
          imgUrl={IS_BOY_FIRST ? IMG_GIRL : IMG_MAN}
          instagramId={!isAlt ? (IS_BOY_FIRST ? IG_GIRL : IG_BOY) : ''}
          description={IS_BOY_FIRST ? GIRL_PARENT_NAME : BOY_PARENT_NAME}
        />
      </Box>
    </Box>
  );
}

export default React.memo(CoupleInfo);
